import React, { Component } from "react";
 
class Demo extends Component {
  render() {
    return (
        <>
        <section className="background--blue-darker">
            <div className="container">
                <div className="head">
                    <h1>Demo</h1>
                    <p className="color--grey">I'm Julia, a freelance web developer based in London, 
                    specialising in building exceptional, bespoke websites using simple html/css or Wordpress, 
                    as well as complex web apps with custom logic for example with React/NodeJS.</p>
                </div>
            </div>
        </section> 
        <section >
            <div className="container">
                <div className="home-content">
                    <p>Demo content</p>
                </div>
            </div>
        </section> 
    </> 
    );
  }
}
 
export default Demo;

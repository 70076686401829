import React, { Component } from "react";
 
class Footer extends Component {
  render() {
    return (
        <footer className="background--blue-darker">
            <div className="container">
                <div className="col-12">
                  <div className="col-2 col-s-12">
                    <div>&copy;2020</div>
                    <div>Julia Ferenczi</div>
                    <div>London, UK</div>
                  </div>
                  <div className="col-10 col-s-12">
                    <ul>
                      <li><a href="tel:+447957493632"><i className="fa fa-mobile"></i>Call me on +44 795749 3632</a></li>
                      <li><a href="mailto:jferenczi@yahoo.com"><i className="fa fa-at"></i>Send me an email to jferenczi 'at' yahoo.com</a></li>
                      <li><a href="https://twitter.com/ferenczi_julia" target="_blank"><i className="fa fa-twitter"></i>Twitter</a></li>
                      <li><a href="https://www.linkedin.com/in/juliaferenczi/" target="_blank"><i className="fa fa-linkedin"></i>Linkedin</a></li>
                    </ul>
                  </div>
                </div>
            </div>
        </footer>
    );
  }
}
 
export default Footer;

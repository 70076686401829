import React, { Component } from "react";
 
class Service extends Component {
  render() {
    return (
        <>
            <section className="background--blue-darker">
                <div className="container">
                    <div className="head">
                        <h1>Web developer services</h1>
                        <p className="color--orange"><h2>New websites, web applications, existing website maintenance.</h2></p>
                        <p className="color--grey">With 15+ years of experience I have a deep understanding of the ins and outs 
                            of developing a functioning website that matches the needs of your business.
                            Need a simple fix to your site? Or a new image gallery for 
                            your existing site? As a freelance web developer, I'm here to get it done for you.</p>
                    </div>
                </div>
            </section> 
            <section >
                <div className="container">
                    <div className="home-content">
                        <p>Services content</p>
                    </div>
                </div>
            </section> 
        </>   
    );
  }
}
 
export default Service;

import React, { Component } from "react";
import {
  Route,
  BrowserRouter
} from "react-router-dom";
import Home from "./Home";
import Service from "./Service";
import About from "./About";
import Contact from "./Contact";
import Demo from "./Demo";
import Footer from "./Footer";
import Nav from "./Nav";
import "../style/main.scss";

class App extends Component {
  render() {
    return (
      <BrowserRouter>
            <nav className="nav row">
              <Nav />
            </nav>
            <main className="">              
              <section>
                  <div className="content">
                    <Route exact path="/" component={Home}/>
                    {/* <Route path="/services" component={Service}/> */}
                    <Route path="/about" component={About}/>
                    <Route path="/contact" component={Contact}/>
                    <Route path="/demo" component={Demo}/>                    
                  </div>                  
              </section>            
            </main>
            <Footer />
      </BrowserRouter>
    );
  }
}

export default App;

import React, { useState } from "react";
import {
    NavLink
  } from "react-router-dom";
 
function Nav() {

  let [checked, setChecked, ] = useState(false);

  const handleMenuClick = () => {
    console.log(checked);
         setChecked(checked === true ? false : true);
  }
    return (
    <div className="container">
        <header ><NavLink exact to="/"><span>Julia Ferenczi</span></NavLink></header>
        <ul className="menu">
          {/* <li><NavLink to="/services">Services</NavLink></li> */}
          <li><NavLink to="/about">About</NavLink></li>
          <li><NavLink to="/contact">Contact</NavLink></li>
          {/* <li><NavLink to="/demo">Demo</NavLink></li> */}
        </ul>
        <div className="menu-mobile nav">
            <input type="checkbox" id="nav-check" checked={checked} />
            <div className="nav-btn ">
                <label for="nav-check" onClick={handleMenuClick}>
                  <span></span>
                  <span></span>
                  <span></span>
              </label>
            </div>
            <div className="test">
              <ul className="menu-second">
                {/* <li><NavLink to="/services">Services</NavLink></li> */}
                <li onClick={handleMenuClick}><NavLink to="/about">About</NavLink></li>
                <li onClick={handleMenuClick}><NavLink to="/contact">Contact</NavLink></li>
                <li onClick={handleMenuClick}><a href="https://twitter.com/ferenczi_julia" target="_blank"><i className="fa fa-twitter"></i></a></li>
                <li onClick={handleMenuClick}><a href="https://www.linkedin.com/in/juliaferenczi/" target="_blank"><i className="fa fa-linkedin"></i></a></li>
                {/* <li><NavLink to="/demo">Demo</NavLink></li> */}
              </ul>
            </div>
        </div>
        <ul className="menu-social">
          <li><a href="https://twitter.com/ferenczi_julia" target="_blank"><i className="fa fa-twitter"></i></a></li>
          <li><a href="https://www.linkedin.com/in/juliaferenczi/" target="_blank"><i className="fa fa-linkedin"></i></a></li>
        </ul>
      </div>
    );
}
 
export default Nav;
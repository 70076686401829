import React, { Component } from "react";
 
class Home extends Component {
  render() {
    return (
        <>
            <section className="background--blue-darker">
                <div className="container">
                    <div className="head">
                        <h1>Web developer, Full-stack developer</h1>
                        <p className="color--orange"><h2>Websites</h2><h2>Web applications</h2></p>
                        <p className="color--grey">With 15+ years of experience I have a deep understanding of the ins and outs 
                            of developing a functioning website that matches the needs of your business.
                            Need a simple fix to your site or web application? Or a new image gallery for 
                            your existing site? Would you like to see your data value? Or change to the newest technology?
                            Doesn't matter how old is your code. I'm here to get it done for you.</p>
                    </div>
                </div>
            </section> 
            <section >
                <div className="container">
                    <div className="home-content col-12">
                        <div className="col-4 home-content--ui">
                            <div className="home-content--icon">
                                <i className="fa fa-html5"></i>
                            </div>
                            <h3>Front-end / UI</h3>
                            <p>Responsive designed webpages for mobile, PC and tablets. 
                                CSS Animation with Transition and Transform for increaseing the user experiment</p>
                            <ul>
                                <li>Html5</li>
                                <li>CSS / SCSS</li>
                                <li>Javascript / JQuery</li>
                                <li>Photoshop / Adobe</li>
                            </ul>
                        </div>
                        <div className="col-4 home-content--bl">
                            <div className="home-content--icon">
                                <i className="fab fa-react"></i>
                            </div>
                            <h3>Business logic</h3>
                            <p>Single page application for fast interactions on and in between pages. 
                                React Web App with API back-end as a solid foundation.</p>
                            <ul>
                                <li>React / Javascript libraries</li>
                                <li>Java / Spring</li>
                                <li>C# / .Net</li>
                                <li>NodeJS</li>
                            </ul>
                        </div>
                        <div className="col-4 home-content--data">
                            <div className="home-content--icon">
                                <i className="fa fa-database"></i>
                            </div>
                            <h3>Content</h3>
                            <p>Storing information, show and analysis data to be valuable content from an sql or non-sql based data server.</p>
                            <ul>
                                <li>SQL</li>
                                <li>Elasticsearch / Kibana</li>
                                <li>Oracle / PLSql / SQLDeveloper</li>
                                <li>MySql / PostgreSql</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section> 
        </>   
    );
  }
}
 
export default Home;

import axios from "axios";
import React, { useState } from "react";
 
function Contact() {

    let [name, setName, ] = useState("");
    let [email, setEmail] = useState("");
    let [message, setMessage] = useState("");
    let [successCall, setSuccessCall] = useState();
    let [style1, setStyle1] = useState();
    let [style2, setStyle2] = useState();
    let [style3, setStyle3] = useState();

    const cleareForm = () => {        
        setEmail("");
        setName("");
        setMessage("");
        setSuccessCall("");
        setStyle1({});
        setStyle2({});
        setStyle3({});
    }

    const cleareFields = () => {        
        setEmail("");
        setName("");
        setMessage("");
    }

    const handleSend = async () => {
        if(validation()){
            setSuccessCall("Please fill all required fields!");
            return false;
        }
        const params = { email: email, name: name, message: message };
         await call(params);          
    }

    const validation = () => {        
        setStyle1(name === "" ? reqStyle : {});
        setStyle2(email === "" ? reqStyle : !/\S+@\S+\.\S+/.test(email) ? validEmailStyle : {});
        setStyle3(message === "" ? reqStyle : {});
        return ((name === "") || (email === "" || !/\S+@\S+\.\S+/.test(email)) || (message === "")) ;
    }

    const call = (params) => {
        return axios.get("http://juliaferenczi.hu/sendMail", { params })
            .then((response) => {
                if (response.status === 200) {
                    setSuccessCall("Thank you for your interest. I will response you as soon as possible");
                    cleareFields();
                    return response;
                }
                setSuccessCall("Thank you for your interest, but the email wasn't arrive. Please choose an other type to keep contact with me.");
            });
    }

    const reqStyle = {
        backgroundImage: "linear-gradient(to right, white , #ff9800)"
    }

    const validEmailStyle = {
        backgroundImage: "linear-gradient(to right, white , #ff9800)",
        color: "red"
    }
    
    return (
        <>
        <section className="background--blue-darker">
            <div className="container">
                <div className="head">
                    <h1>Contact</h1>
                    <p className="color--grey">If you are interested in working together or wish to find out more about 
                    what I can help with, use one of the below methods to get in touch with me.</p>
                </div>
            </div>
        </section> 
        <section >
            <div className="container">
                <div className="contact-content">
                    <div className="contact-left col-6 col-s-12">
                        <h2>Send me a message</h2>
                        <div className="floating-label">                            
                            <input type="text" name="name" id="name" placeholder="Name*" maxLength="20" 
                                value={name} onChange={event => setName(event.target.value)} style={style1}/>
                            <label>Name*</label>
                        </div>
                        <div className="floating-label">                            
                            <input type="text" name="email" id="email" placeholder="Email*" autocomplete="off" 
                                value={email} maxLength="60" onChange={event => setEmail(event.target.value)} style={style2}/>
                            <label>Email*</label>
                        </div>
                        <div className="floating-label">                            
                            <textarea name="message" id="message" placeholder="Message*" autocomplete="off" maxLength="180"
                                value={message} onChange={event => setMessage(event.target.value)} style={style3}/>
                            <label>Short message*</label>
                        </div>
                        <div>
                            <button className="button-clear" onClick={cleareForm}>Clear</button>
                            <button className="button-send" onClick={handleSend}>Send enquiry</button>
                        </div>
                        <div>{successCall}</div>
                    </div>
                    <div  className="contact-right col-6 col-s-12">
                        <h2>Contact details</h2>
                        <div>
                            <ul>
                                <li><a href="tel:+447957493632"><i className="fa fa-mobile"></i>+44 795749 3632</a></li>
                                <li><a href="mailto:jferenczi@yahoo.com"><i className="fa fa-at"></i>jferenczi 'at' yahoo.com</a></li>
                                <li><a href="https://twitter.com/ferenczi_julia" target="_blank"><i className="fa fa-twitter"></i>Twitter</a></li>
                                <li><a href="https://www.linkedin.com/in/juliaferenczi/" target="_blank"><i className="fa fa-linkedin"></i>Linkedin</a></li>
                            </ul>
                        </div>
                    </div>                    
                </div>
            </div>
        </section> 
    </>   
    );
}
 
export default Contact;
